<template>
	<div class="bg-w pd20">
		
		<div class="flex center alcenter">
			<div class="flex" style="width: 600px;">
				<div class=" col2 count-box-item  flex alcenter center">
					<div class="text-center" >
						<div class="ft18 ftw600 cl-red">¥{{datas.recharge}}</div>
						<div class="mt16 ft14 cl-notice">余额充值</div>
					</div>
				</div>
				<div class=" col2 count-box-item ml30 flex alcenter center">
					<div class="text-center" >
						<div class="ft18 ftw600 cl-green">¥{{datas.use}}</div>
						<div class="mt16 ft14 cl-notice">余额消费</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt20">
			
			<ve-line :data="chartLineData"></ve-line>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			chartLineData(){
				return {
					columns: ['日期', '充值金额', '消费金额'],
					rows: this.datas.tables
				}
			},
			
		},
		data(){
	
			return {
				
				
			}
		}
	}
</script>

<style>
	.count-box-item{
		height: 126px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
	}
</style>