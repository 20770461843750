<template>
	<div class="pd20">
		<a-spin :spinning="loading">
		<div class="ft20 cl-black cl-main ftw500">运营数据汇总</div>
		<div class="form-search-box mt20">
				<a-form layout="inline">
					<a-form-item label="筛选日期">
						<a-range-picker showTime v-model="days" valueFormat="YYYY-MM-DD HH:mm:ss" />
					</a-form-item>
					<a-form-item label="门店">
						<a-select v-model="mendianId" style="width: 180px;">
							<a-select-option v-for="(item,index) in mendians" :key="index" :value="item.mendian_id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
					
					</a-form-item>
				</a-form>
			</div>
			
		<div  class="mt20">
			<a-tabs default-active-key="weixin" @change="changeAct">
				<a-tab-pane key="weixin" tab="在线支付(微信)">
				  <tableWeixin v-if="datas!== null &&  datas.weixin" :datas="datas.weixin"></tableWeixin>
				</a-tab-pane>
			      <a-tab-pane key="money" tab="会员储值">
			        <tableMoney v-if="datas!== null &&  datas.money" :datas="datas.money"></tableMoney>
			      </a-tab-pane>
				  <a-tab-pane key="integral" tab="积分">
				      <tableIntegral v-if="datas!== null &&  datas.integral" :datas="datas.integral"></tableIntegral>
				  </a-tab-pane>
			      <a-tab-pane key="coupon" tab="优惠券">
			        <tableCoupon v-if="datas!== null &&  datas.coupon" :datas="datas.coupon"></tableCoupon>
			      </a-tab-pane>
			      
				
				 
		</a-tabs>
		</div>	
		</a-spin>
	</div>
</template>

<script>
	import tableMoney from './components/table/money.vue';
	import tableCoupon from './components/table/coupon.vue';
	import tableIntegral from './components/table/integral.vue';
	
	import  tableWeixin from './components/table/weixin.vue';
	export default{
		components:{
			tableMoney,
			tableCoupon,
			tableIntegral,
			
			tableWeixin
		},
		data(){
			return {
				days:['',''],
				loading:false,
				datas:null,
				type:'weixin',
				mendians:[],
				mendianId:'',
			}
		},
		created(){
			this.loaddata();
			this.getAllMendian();
		},
		methods:{
			searchAct(){
				console.log(this.days);
				this.loaddata();
			},
			changeAct(e){
				this.type = e;
				this.loaddata(); 
			},
			getAllMendian(){
				this.$http.api('admin/getAllMendian').then(res=>{
					this.mendians  = res.mendian;
				}).catch(res=>{
					console.log(res);
				})
			},
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				
				this.$http.api('admin/table',{
					bg:this.days[0],
					end:this.days[1],
					type:this.type,
					mendianId:this.mendianId
				}).then(res=>{
					console.log(res);
					this.days = res.days;
					this.datas  = res.datas;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
		}
		
	}
</script>

<style>
	
	.count-box {
		width: 100%;
		height: 260px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 24px;
	}
	
	.count-box div {
		line-height: 1;
	}
	
</style>