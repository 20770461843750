<template>
	<div class="bg-w pd20">
		
		<div>
			<ve-pie :data="chartPieData"  ></ve-pie>
		</div>
		<div class="mt20">
			
			<ve-line :data="chartLineData"  ></ve-line>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			chartLineData(){
				return {
					columns: ['日期', '收银支付','余额充值','在线买单','开通次卡','抢购','囤货','商城购物'],
					rows: this.datas.tables
				}
			},
			chartPieData(){
				return {
					columns: ['类型', '总金额'],
					rows: this.datas.pie
				}
			}
		},
		data(){
	
			return {
				
				
				
			}
		}
	}
</script>

<style>
	
</style>